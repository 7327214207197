import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCalendarDays,
  faHeart,
  faQuoteRight,
  faQuoteLeft
} from '@fortawesome/free-solid-svg-icons'
import { faFaceSmile } from '@fortawesome/free-regular-svg-icons'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import BackToTopButton from '../../components/BackToTopButton'
import Link from '../../components/LinkWrapper'
import i18n from '../../locales/he.yaml'

const ChallengeQuery = graphql`
  query ChallengeQuery {
    image1: file(relativePath: { eq: "עוגת-ישראל-מפוסלת-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
    image2: file(relativePath: { eq: "ילדה-ישראלית-מפוסלת-בבצק-סוכר.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 600, layout: CONSTRAINED)
      }
    }
    survival: file(
      relativePath: {
        eq: "קארין-עמנואל-עם-עוגת-הישרדות-ענקית-מרחפת-בגובה-מטר-מפוסלת-בבצק-סוכר-בעבודת-יד.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, width: 600, layout: CONSTRAINED)
      }
    }
  }
`

const ChallengeTemplate = ({ pathname, search, data }) => {
  const [iframeUrl, setIframeUrl] = useState(null)
  useEffect(() => {
    setIframeUrl(
      'https://www.karinnarts.co.il/forms/?ff_landing=37&embedded=1&' +
        search
          .slice(1)
          .split('&')
          .filter(
            (item) =>
              item.split('=')[0] !== 'ns_url' && item.split('=')[0] !== 'mid'
          )
          .join('&')
    )
  }, [])
  const { image1, image2, survival } = data

  const BULLETS_IMAGE = [image1, image2]

  return (
    <>
      <BackToTopButton selectorId='challenge' />
      <div className='hero has-navbar-fixed-top'>
        <div className='hero-body is-padding-top-6 is-padding-bottom-6'>
          <div className='container'>
            <nav className='breadcrumb' aria-label='breadcrumbs'>
              <ul>
                <li>
                  <Link to={'/academy/'}>{i18n.header.academy}</Link>
                </li>
                <li className='is-active'>
                  <Link to={pathname} aria-current='page'>
                    {i18n.header.challenge}
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className='hero has-background-israel-lighter'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <div className='columns is-centered'>
              <div className='column'>
                <p className='is-size-5 has-text-israel'>
                  {i18n.challenge.tagline}
                </p>
                <h1 className='is-size-2 is-family-third is-margin-bottom-6'>
                  {i18n.challenge.title}
                </h1>
                <h2 className='is-size-6'>{i18n.challenge.subtitle}</h2>
                <div className='has-text-centered is-margin-top-6'>
                  <a
                    href={`${pathname}#registration`}
                    className='button is-israel is-medium'
                  >
                    {i18n.challenge.callToAction}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {i18n.challenge.bullets.map((bullet, index) => (
        <div
          id={index === 1 ? 'challenge' : undefined}
          key={index}
          className={clsx('hero', {})}
        >
          <div className='hero-body'>
            <div className='container'>
              <div
                className={clsx(
                  'columns is-mobile is-multiline is-centered is-vcentered',
                  {
                    'direction-row-reverse': index % 2 === 1
                  }
                )}
              >
                <div
                  className={clsx(
                    'column is-full-real-mobile is-10-mobile is-7-tablet is-6-desktop is-5-widescreen',
                    {
                      'is-offset-1-widescreen': index % 2 === 1
                    }
                  )}
                >
                  <GatsbyImage
                    image={getImage(BULLETS_IMAGE[index])}
                    title={bullet.image.title}
                    alt={bullet.image.alt}
                    className='live-image'
                    style={{
                      maxWidth: getImage(BULLETS_IMAGE[index]).width,
                      margin: '0 auto' // used to center the image
                    }}
                    loading='eager'
                  />
                  <div className='is-flex justify-center content'>
                    <p className='has-text-centered has-text-gold-black'>
                      {bullet.image.title}
                    </p>
                  </div>
                </div>
                <div
                  className={clsx(
                    'column is-full-mobile is-10-tablet is-6-desktop',
                    {
                      'is-offset-1-widescreen': index % 2 === 0
                    }
                  )}
                >
                  <div className='content'>
                    <h3>{bullet.title}</h3>
                    <p>{bullet.description}</p>
                    <strong>{bullet.question}</strong>
                  </div>
                </div>
              </div>
              {index === 0 && (
                <div className='has-text-centered is-margin-top-6'>
                  <a
                    href={`${pathname}#registration`}
                    className='button is-israel is-medium'
                  >
                    {i18n.challenge.callToAction}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <FontAwesomeIcon
              icon={faCalendarDays}
              className='has-text-israel is-margin-bottom-4'
              size='lg'
            />
            <div className='is-margin-bottom-4'>
              <h3>{i18n.challenge.when.title}</h3>
            </div>
            <p>{i18n.challenge.when.description}</p>
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content has-text-centered'>
            <FontAwesomeIcon
              icon={faFaceSmile}
              className='has-text-israel is-margin-bottom-4'
              size='lg'
            />
            <div className='is-margin-bottom-4'>
              <h3>{i18n.challenge.who.title}</h3>
            </div>
            <p>{i18n.challenge.who.description}</p>
          </div>
        </div>
      </div>
      {iframeUrl && (
        <div id='registration' className='hero'>
          <div className='hero-body is-padding-bottom-0'>
            <div className='container content has-text-centered'>
              <FontAwesomeIcon
                icon={faHeart}
                className='has-text-israel is-margin-bottom-4'
                size='lg'
              />
              <div className='is-margin-bottom-4'>
                <h3>{i18n.challenge.registration.title}</h3>
              </div>
              <p>{i18n.challenge.registration.hint}</p>
              <div className='columns is-centered'>
                <div className='column is-full-mobile is-10-tablet is-6-desktop'>
                  <iframe
                    id='fluentform'
                    width='100%'
                    height='100%'
                    loading='lazy'
                    style={{ minHeight: '400px', width: '100%' }}
                    frameBorder='0'
                    onLoad={(event) => {
                      event.target.style.height =
                        event.target.contentWindow.document.body.scrollHeight +
                        'px'
                    }}
                    src={iframeUrl}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='hero'>
        <div className='hero-body'>
          <div className='container content'>
            <div className='has-text-centered is-margin-bottom-6'>
              <h3>{i18n.challenge.testimonials.title}</h3>
            </div>
            {i18n.challenge.testimonials.testimonials.map(
              (testimony, index) => (
                <div key={testimony.title} className='columns is-centered'>
                  <div className='column is-6-widescreen is-8-desktop is-10-tablet is-12-mobile'>
                    <div
                      className={clsx(
                        'columns is-flex-direction-column is-mobile is-align-items-center',
                        { 'direction-column-reverse': testimony.isEnglish }
                      )}
                    >
                      <div className='column is-align-self-flex-start'>
                        <FontAwesomeIcon icon={faQuoteRight} size='lg' />
                      </div>
                      <div
                        className='column is-padding-top-0 is-padding-bottom-0'
                        dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                      >
                        <h4 className='has-text-gold-black is-margin-bottom-0'>
                          {testimony.title}
                        </h4>
                      </div>
                      <div className='column is-align-self-flex-end'>
                        <FontAwesomeIcon icon={faQuoteLeft} size='lg' />
                      </div>
                    </div>
                    <div
                      className={clsx('columns is-align-items-start', {
                        'direction-row-reverse': testimony.isEnglish
                      })}
                    >
                      <div className='column is-narrow'>
                        <p className='has-text-centered has-text-weight-bold'>
                          {testimony.name}
                        </p>
                      </div>
                      <div className='column'>
                        <p
                          className='is-margin-bottom-0'
                          dir={testimony.isEnglish ? 'ltr' : 'rtl'}
                        >
                          {testimony.text}
                        </p>
                      </div>
                    </div>
                    {index <
                      i18n.challenge.testimonials.testimonials.length - 1 && (
                      <hr />
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className='hero'>
        <div className='hero-body'>
          <div className='container'>
            <div className='has-text-centered'>
              <a
                href={`${pathname}#registration`}
                className='button is-israel is-medium'
              >
                {i18n.challenge.callToAction}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-israel-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-mobile is-multiline is-centered'>
              <div className='column content is-margin-bottom-0 is-full-mobile is-10-tablet is-6-desktop'>
                <h3>{i18n.challenge.about.title}</h3>
                <p>{i18n.challenge.about.description}</p>
              </div>
              <div className='column is-4-fullhd is-5-widescreen is-half-tablet is-10-mobile is-offset-2-fullhd is-offset-1-widescreen is-order-3-touch'>
                <GatsbyImage
                  image={getImage(survival)}
                  title={i18n.challenge.about.mainImage.title}
                  alt={i18n.challenge.about.mainImage.alt}
                  className='live-image'
                  style={{
                    maxWidth: getImage(survival).width,
                    margin: '0 auto' // used to center the image
                  }}
                />
                <div className='is-flex justify-center content'>
                  <p className='has-text-centered has-text-gold-black'>
                    {i18n.challenge.about.mainImage.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='hero has-background-israel-lighter'>
        <div className='hero-body'>
          <div className='container'>
            <div className='has-text-centered'>
              <a
                href={`${pathname}#registration`}
                className='button is-israel is-medium'
              >
                {i18n.challenge.callToAction}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Challenge = ({ location: { pathname, search } }) => {
  const data = useStaticQuery(ChallengeQuery)
  return (
    <Layout>
      <Seo
        metadata={i18n.challenge.metadata}
        pathname={pathname}
        og={{
          image: getSrc(data.image1),
          alt: i18n.challenge.mainImage.alt
        }}
        schemaMarkup={{
          breadcrumbs: [
            { name: i18n.header.academy, path: '/academy/' },
            {
              name: i18n.header.challenge,
              path: '/academy/challenge/'
            }
          ]
        }}
      />
      <ChallengeTemplate pathname={pathname} search={search} data={data} />
    </Layout>
  )
}

export default Challenge
